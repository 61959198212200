<template>
  <div class="iframeModule">
    <iframe id="iframe" name="ifrmname" frameborder="0" :src="srcs"></iframe>
  </div>
</template>

<script>
  import { mapState, mapGetters } from "vuex";
  window.addEventListener(
    "message",
    function(e) {
      // global.router.push("/userOrSafety");
    },
    false
  );

  export default {
    data() {
      return {
        userMes: null,
        srcs: "",
        newStr: new Date().getTime(),
      };
    },
    computed: {
      ...mapGetters(["iframeStateGetter"]),
      ...mapState({
        src: state => state.search.headerText.url
      }),
      cUrl() {
        let newStr = new Date();
        return this.srcs + "?newDate=" + newStr;
      }
    },
    watch: {
      iframeStateGetter() {
        document.getElementById("iframe").src = "javascript:false";
      },
      src(val) {
        if (this.srcs.indexOf("undefined") != -1) {
          this.srcs = localStorage.getItem("iframeUrl");
        } else {
          this.srcs =
            JSON.parse(localStorage.getItem("setHeaderText")).url +
            `?firmId=${localStorage.getItem("atPresentFirmId")}&sessionStr=${
              this.userMes.sessionStr
            }&tel=${this.userMes.tel}&userId=${
              this.userMes.userId
            }&symbolId=${localStorage.getItem("symbolIds")}&firmCode=1&date=${
              this.newStr
            }`;
          localStorage.setItem("iframeUrl", this.srcs);
        }
      }
    },
    created() {
      let that = this;
      this.userMes = this.$store.state.common.sessionInfo;
      that.srcs =
        JSON.parse(localStorage.getItem("setHeaderText")).url +
        `?firmId=${localStorage.getItem("atPresentFirmId")}&sessionStr=${
          that.userMes.sessionStr
        }&tel=${that.userMes.tel}&userId=${
          that.userMes.userId
        }&symbolId=${localStorage.getItem("symbolIds")}&firmCode=1&date=${
          this.newStr
        }`;
      if (that.srcs.indexOf("undefined") != -1) {
        that.srcs = localStorage.getItem("iframeUrl");
      } else {
        localStorage.setItem("iframeUrl", that.srcs);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .iframeModule {
    height: 100%;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
</style>
